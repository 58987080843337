import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import '../../assets/css/evento.css';

import ima from '../../assets/images/temp/50x50.png';
import logo_ampip from '../../assets/images/segunda_reunion/logoampip.png';
import ima2 from '../../assets/images/temp/50x50.png';
import { Auth } from '../../utils/AuthContext';
import Login from '../registro/Login';


const secciones = [
  //{ nombre: 'AGENDA', url: '/#agenda', nombre_en: 'SCHEDULE' },
  //{ nombre: 'COSTOS', url: '/costos', nombre_en: '' },
  //{ nombre: 'CONFERENCISTAS', url: '/#conferencistas', nombre_en: '' },
  //{ nombre: 'PATROCINADORES', url: '/#patrocinadores' },
  //{ nombre: 'HOSPEDAJE', url: '/#hospedaje' },
  //{ nombre: 'MEMBRESÍA', url: '/#membresia', nombre_en: 'MEMBERSHIP' },

  //{ nombre: 'ACERCA DE', url: '/#acerca-de', nombre_en: 'ABOUT US' },
  //{ nombre: 'CONFERENCISTAS', url: '/#conferencistas', nombre_en: '' },
  //{ nombre: 'GRUPO ANFITRIÓN', url: '/grupo-anfitrion', nombre_en: '' },
  //{ nombre: 'REGISTRO', url: '/registro', nombre_en: 'REGISTER' },
  //{nombre:'TRANSMISIÓN', url:'/transmision'},
];

const success = () => {
  mensaje('success', 'Bienvenido', '');
};


const mensaje = (tipo, titulo, texto) => {

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}

const Header = (props) => {

  const { usuario } = useContext(Auth);
  const [modal_entrar, setModalEntrar] = useState(false);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_submenu, setMostrarSubmenu] = useState(false);
  const { setIdioma, idioma } = props;

  const [scroll, setScroll] = useState(0);
  const [ver_color, setVerColor] = useState(true);
  const [ver_lugar, setVerLugar] = useState(false);

  useEffect(() => {
    if (usuario) {
      setMostrarLogin(false);
    } else {

      setMostrarLogin(true);//cambiar a true
    }
  }, [usuario]);



  let location = useLocation();

  const [redireccionar_en_vivo, setRedireccionarEnVivo] = useState(false);


  useEffect(() => {
    if (redireccionar_en_vivo) {
      window.location.href = "/transmision";
    }
  }, [redireccionar_en_vivo]);

  useEffect(() => {
    if (location.pathname === '' || location.pathname === '/' || location.pathname === '/registro' || location.pathname === '/costos' || location.pathname === '/transmision') {
      setVerLugar(false)
    } else {
      setVerLugar(true)
    }

  }, [location]);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
      setVerColor(scrollCheck);
    })
  }, [])

  let visualizar_secciones = secciones.map((seccion) => {

    if (seccion.url === '/transmision') {
      if (!usuario) {
        return (
          <>

          </>
        );
      }
    }


    return (
      <Link className="titles" to={seccion.url}>
        <span className={location.pathname + '' + location.hash === seccion.url || location.pathname === seccion.url ? "seccion_sel " : "seccion "} >
          {idioma === 'es' ? seccion.nombre : seccion.nombre_en}
        </span>
      </Link>
    );
  });

  return (
    <>

      <Modal
        title="INICIA SESIÓN"
        open={mostrar_login}
        onOk={() => { setMostrarLogin(false) }}
        onCancel={() => { setMostrarLogin(false) }}
        footer={null}
      >
        <Login onResponse={() => { setMostrarLogin(false); setRedireccionarEnVivo(true); }} onClick={success} />
      </Modal>

    </>
  );
}

export default Header;