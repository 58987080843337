import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import ICalendarLink from "react-icalendar-link";
import { Element } from "react-scroll";

import Agenda from "./Agenda";
import Ponentes from "./Ponentes";

import "../../assets/css/webinars.css";

import banner from "../../assets/images/reunion_anual/BANNER/banner_home_.jpg";
import img_bienvenida from "../../assets/images/tercera_reunion/ACERCA/fotos_ampip_acercade.png";

import pdf_patrocinadores from '../../assets/documentos/PATROCINADORES.pdf';

import btn_ayuda from "../../assets/images/tercera_reunion/btn_whastapp.png";
import btn_ayuda_en from "../../assets/images/tercera_reunion/btn_whastapp.png";

import logo_banner_ergistro from "../../assets/images/reunion_anual/BANNER/logo_AMPIP_2023convencion_home.png";
import btn_registro from "../../assets/images/reunion_anual/BANNER/btn_registro cerrado.png";

import fecha from "../../assets/images/tercera_reunion/HOME/icono_calendario.png";
import lugar from "../../assets/images/tercera_reunion/HOME/icono_ubicacion.png";

import back_datos from "../../assets/images/tercera_reunion/back_estadisticas.jpg";

import "../../assets/css/conferencia.css";
import Contador from "../evento/Contador";
import Membresia from "../membresia/Membresia";
import Hospedaje from "../membresia/Hospedaje";
import CarouselPatrocinadores from "../evento/CarouselPatrocinadores";

const Webinars = (props) => {
  const { idioma } = props;
  

  return (
    <>
      <Row>
        <Col span={24}>
          <Row style={{ position: "relative" }}>
            <Col span={24} style={{ position: "relative" }}>
              <img src={banner} style={{ width: "100%" }} />
            </Col>
            <Col
              span={24}
              offset={0}
              className="contenedor_banner_menu"
              style={{ position: "absolute", width: "100%" }}
            >
              <Row justify="center">
                <Col xs={0} md={24}>

                <br />
                </Col>
                <Col xs={0} md={0}>
                  <Contador
                    dateTarget="09/07/2022 10:00:00 AM"
                    idioma={idioma}
                  />
                </Col>
                <Col xs={0} md={24}>

                <br />
                </Col>
                <Col xs={12} md={7}>
                  <img src={logo_banner_ergistro} style={{ width: "100%" }} />
                </Col>

                <Col xs={0} md={24}>
                  <br />
                  <br />
                </Col>
                <Col xs={20} md={20}>
                  <p className="text_banner">
                  15 al 18 de noviembre 2023 | JW MARRIOT- Los Cabos, México
                  </p>
                </Col>
                
                <Col xs={0} md={14}>
                  <br />
                  <br />
                  <br />
                </Col>
                <Col xs={0} md={24}>
                  {/*<Link to="registro" className="btn_aqua_">
                    
  </Link>*/}<img src={btn_registro} style={{width:'350px'}} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="contenedor_conferencias">
        <Col id="agenda" span={24} className="back_agenda">
          <Element name="#agenda"></Element>
          <Agenda idioma={idioma} />
        </Col>
        <Col id="conferencistas" span={0}>
          <Element name="#conferencistas"></Element>
          <Ponentes idioma={idioma} />
        </Col>
        <Col span={24}>
          <Element name="#conferencistas"></Element>
          <Ponentes />
        </Col>
        <Col id="hospedaje" span={24}>
          <Element name="#hospedaje"></Element>
          <Hospedaje idioma={idioma} />
        </Col>
        <Col span={24} className="ayuda_back back_naranja paddin_contenedor">
          <Row justify="center" align="middle">
            <Col xs={{ span: 12, offset: 2 }} md={{ span: 12, offset: 0 }}>
              <p className="bold_font dudas_btn color_azul">
                {idioma === "es"
                  ? "Tengo dudas sobre mi inscripción"
                  : "I have questions about my registration"}
              </p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 6, offset: 0 }}>
              <a
                href={
                  idioma === "es"
                    ? "https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                    : "https://api.whatsapp.com/send?phone=525557400333&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                }
                target="_blank"
              >
                <img
                  src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                  style={{ width: "230px" }}
                />
              </a>
            </Col>
            <Col xs={{ span: 8, offset: 0 }} md={{ span: 0, offset: 0 }}>
              <a
                href={
                  idioma === "es"
                    ? "https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                    : "https://api.whatsapp.com/send?phone=525557400333&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                }
                target="_blank"
              >
                <img
                  src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                  style={{ width: "100px" }}
                />
              </a>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Element name="#membresia"></Element>
          <Membresia idioma={idioma} />
        </Col>
        <Col span={24}>

        <Element name="#patrocinadores"></Element>
        <CarouselPatrocinadores />
        </Col>
        <Col span={0} className="back_azul paddin_contenedor">

          <Row justify="center" align="middle">
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
              <p className="bold_font costo_patrocinio_hoome color_azul_claro_2">
              ¡Forma parte del futuro industrial de México!<br />
              <span className="color_blanco"><strong>Para más información sobre patrocinios</strong></span>
              </p>
            </Col>
            <Col xs={24} md={0}>
              <br />
            </Col>
            <Col xs={{ span: 20, offset: 0 }} md={{ span: 6, offset: 0 }}>
              <Link className="btn_patrocinio_hoome" to={pdf_patrocinadores} target="_blank">
              <strong className="bold_font">DA CLIC AQUÍ</strong>
              </Link>
            </Col>
            <Col xs={24} md={0}>
              <br />
            </Col>
          </Row>
        </Col>

       
        
        
      </Row>
    </>
  );
};

export default Webinars;
