import React, { useContext, useState } from 'react';
import { Row, Col, Modal, notification } from 'antd';
import { Link } from 'react-router-dom';
import { Auth } from "../../utils/AuthContext";
import '../../assets/css/ingenieria/evento.css';

import camion from '../../assets/images/temp/imagen200x200.png';

import revivir from '../../assets/images/temp/image200x100.png';
import presen from '../../assets/images/temp/image200x100.png';
import pasado from '../../assets/images/temp/image200x100.png';

import ima from '../../assets/images/temp/imagen200x200.png';
import img_mesas from '../../assets/images/temp/image200x100.png';
import img_webinar from '../../assets/images/temp/image200x100.png';
import img_conf from '../../assets/images/temp/image200x100.png';
import img_lanza from '../../assets/images/temp/image200x100.png';




const Home = () =>{

  const { usuario } = useContext(Auth);
  
  return(
    <>
    <Row style={{backgroundColor:'white'}}>
      <Col xs={{span:6, offset:9}} md={{span:4, offset:10}}>
        <br /><br />
        <img alt="logo" style={{width:'100%'}} src={ima}/>
        
      </Col>
      <Col xs={{span:0, offset:0}} md={{span:0, offset:0}}>
        <a href="" target="_blank">
        <img src={img_conf} style={{width:'85%'}} />
        </a>
      </Col>
      <Col xs={{span:0, offset:0}} md={{span:0, offset:0}}>
        <a href="" target="_blank">
        <img src={img_lanza} style={{width:'85%'}} />
        </a>
      </Col>
      <Col xs={{span:0, offset:0}} md={{span:0, offset:0}}>
        <a href="" target="_blank">
          <img src={img_mesas} style={{width:'100%'}} />
        </a>
      </Col>
    </Row>


    <Row justify="center" align="middle" style={{display:'none'}}>

      
      <Col span={24} className="programa" id="programa">
        <Row justify="center" align="middle">
          <Col span={18} align="left">
            <p className="tituPrograma">WEBINARS</p>
          </Col>
          <Col span={24} >
            <Row justify="center" align="middle">
              <Col span={18} className="divPrograma">
               
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <br/><br/><br />
          </Col>
          
        </Row>
      </Col>
      <Col span={6}>
            <img src={camion} style={{width:'100%'}} />
            <br /> <br />
          </Col>
    </Row>
    </>
  );
}

export default Home;