import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import "../../assets/css/agenda.css";

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_agenda.png';

import clinica_golf from '../../assets/documentos/Clinica_de_golf.pdf';
import info_pdf from '../../assets/documentos/Torneo_de_golf.pdf';
import agotado from '../../assets/images/reunion_anual/golf_cupo-agotado.jpg';

import { CloseOutlined } from "@ant-design/icons";


const Agenda = (props) => {
  const [dia, setDia] = useState(1);
  const [ver_order, setVerOrder] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const conferencia = [
    {
      dia: 1,
      hora: "12:00 - 18:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Registro</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Biblioteca</span></>],
        },
      ],
      back: "",
    },
    {
      dia: 1,
      hora: "16:00 - 18:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Sesión ordinaria del Consejo Directivo AMPIP</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Matkú 1</span></>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 1,
      hora: "16:00 - 17:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Comités AMPIP: informe 2022 - 2023 y planeación 2024 - 2025</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          - ASG <span className="color_azul_claro_2">Matkú 2</span><br />
          - Infraestructura y Seguridad <span className="color_azul_claro_2">Matkú 3</span><br /></>],
        },
      ],
      back: "",
    },
    {
      dia: 1,
      hora: "17:00 - 18:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Comités AMPIP: informe 2022 - 2023 y planeación 2024 - 2025</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          - Energía <span className="color_azul_claro_2">Matkú 2</span><br />
          - Promoción <span className="color_azul_claro_2">Matkú 3</span></>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 1,
      hora: "18:30 - 22:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cocktail y cena de bienvenida</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Nak Grill</span><br /><small>(vestimenta casual)</small></>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "08:00 - 09:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Registro</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Foyer Mila</span></>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Ciclo de conferencias</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Mila Ballroom</span></>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "09:15 - 09:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Bienvenida</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Sergio Argüelles </span>Presidente AMPIP</>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "09:30 - 10:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Informe comités AMPIP 2022 - 2023<br /> Panel con los presidentes de los comités</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Claudia Esteves </span>Directora General AMPIP</>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "10:30 - 11:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Economía: estrategias para potenciar la competitividad de México</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Rodrigo Pacheco </span>Periodista especializado</>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "11:30 - 12:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Receso</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "12:00 - 13:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Geopolítica global: escenarios para México</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<><span className="color_naranja">Alfredo Jalife-Rahme</span>, Analista político</>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "13:00 - 14:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Tendencias de transformación digital para el sector de parques industriales.</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Karla Berman </span>Experta en ecosistemas digitales</>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "14:00 - 15:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Comida</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Foyer Norte</span></>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "15:30 - 19:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Tarde libre</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "19:00 - 20:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Conferencia magistral<br />Una visión para México</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Xóchitl Gálvez </span>Representante del Frente Amplio por México</>],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "20:00 - 23:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cena</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Amet Patio</span><br /><small>(vestimenta casual)</small></>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 3,
      hora: "",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Ciclo de conferencias</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Mila Ballroom</span></>],
        },
      ],
      back: "",
    },
    {
      dia: 3,
      hora: "09:00 - 09:45 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Nearshoring: Evolución o Revolución</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Lyman Daniels</span> CBRE<br />
          CBRE</>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 3,
      hora: "09:45 - 11:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Panel de soluciones de energía</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Badir Treviño-Mohamed</span> Creel, García-Cuéllar, Aiza y Enríquez<br />
          <span className="color_naranja">Santiago Sepúlveda</span> Creel, García-Cuéllar, Aiza y Enríquez<br />
          <span className="color_naranja">Santiago Villagómez</span> Energía Real<br />
          <span className="color_naranja">Ena Gutiérrez</span> Enlight<br />
          <span className="color_naranja">Carla Ortiz</span> RER Energy Group</>],
        },
      ],
      back: "",
    },
    {
      dia: 3,
      hora: "11:00 - 11:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Receso</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 3,
      hora: "11:30 - 12:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Informe del Presidente 2022 – 2023
                <br />
                Ceremonia de cierre</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          Mensaje <span className="color_naranja">Sergio Argüelles</span>, Presidente AMPIP saliente</>],
        },
      ],
      back: "",
    },
    {
      dia: 3,
      hora: "12:30 - 13:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Inspiración de un sueño</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_naranja">Chucho Ramírez</span></>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 3,
      hora: "13:30 - 14:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cierre del evento</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 3,
      hora: "14:00 - 16:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Comida</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <span className="color_azul_claro_2">Foyer Norte</span><br /></>],
        },
      ],
      back: "back_dispar",
    },
    
    {
      dia: 4,
      hora: "08:00 - 14:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>
                Torneo de Golf (actividad opcional)</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [<>
          <Row>
            <Col span={16} className=""><br /><br />
            <img src={agotado} className="img_100" />
            <br /><br />
            </Col>
          </Row>
            </>],
        },
      ],
      back: "back_dispar2",
    },
    
  ];

  const ordenes = [
    {orden: 1, texto:[<>
    <p className="titulo_agenda">Comité de Promoción</p>
    <ol className="desc_agenda" type="1">
      <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">José Luis Benitez</span></li>
      <li>Autopresentación de los asistentes</li>
      <li>Presentación de plan de trabajo y acciones relevantes
        <ol type="a">
          <li>Plan de trabajo 2022</li>
        </ol>
      </li>
      <li>Seguimiento y avance de acuerdos
        <ol type="a">
          <li>Información
            <ol type="i">
              <li>Materiales actualizados</li>
              <li>Sitio web</li>
            </ol>
          </li>
          <li>Resumen de leads AMPIP</li>
          <li>Ecosistema de promoción AMPIP</li>
          <li>Eventos
            <ol type="i">
              <li>Promoción con cuerpo diplomático - 20 de octubre 2022</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>Nuevos temas:
        <ol type="a">
          <li>Encuesta sobre percepción y tendencias de la IED en México, 2022 - 2023</li>
        </ol>
      </li>
      <li>Propuestas de eventos y capacitación</li>
    </ol>
    </>]},
    {orden: 2, texto:[<>
      <p className="titulo_agenda">Comité ASG</p>
      <ol className="desc_agenda" type="1">
        <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">Karen Mora</span></li>
        <li>Autopresentación de los asistentes</li>
        <li>Presentación nuevo Secretario Técnico - <span className="color_azul_claro_2">Diego Colunga</span></li>
        <li>Presentación de plan de trabajo y acciones <span className="color_azul_claro_2">relevantes – Karen Mora</span>
          <ol className="q">
            <li>Plan de trabajo 2022</li>
            <li>Acciones relevantes
              <ol type="i">
                <li>Resumen del Sondeo ASG y Sustentabilidad</li>
                <li>Adhesión AMPIP al Pacto Mundial</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Seguimiento y avance de acuerdos
          <ol type="a">
            <li>Perfil de puesto responsable ASG en parques (técnico y directivo) - <span className="color_azul_claro_2">Mario del Bosque</span></li>
            <li>Guía para homologación de conocimientos - <span className="color_azul_claro_2">Carlos Viesca</span></li>
            <li>Directorio - mapa del ecosistema AMPIP - <span className="color_azul_claro_2">Diego Colunga</span></li>
            <li>Reporte de los seminarios:
              <ol>
                <li>Certificaciones Leed, Well, Edge - <span className="color_azul_claro_2">Karen Mora</span></li>
                <li>Código de red - <span className="color_azul_claro_2">Diego Colunga</span></li>
                <li>Programa Nacional de Auditoría Ambiental PROFEPA - <span className="color_azul_claro_2">Rodolfo Morales</span></li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Nuevos temas:
          <ol>
            <li>Presentación proyecto Mariposa Monarca – <span className="color_azul_claro_2">David Eaton</span> - 15 min</li>
            <li>Compromiso social AMPIP – <span className="color_azul_claro_2">Karen Mora</span> – 5 min
              <ol>
                <li>Se reciben propuestas hasta el 15 de noviembre</li>
                <li>Reunión noviembre, Comité ASG decide acción social para 2023</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </>]},
    {orden: 3, texto:[<>
      <p className="titulo_agenda">Comité de Infraestructura y Seguridad</p>
      <ol className="desc_agenda" type="1">
        <li>Bienvenida Presidente del Comité - <span className="color_azul_claro_2">Daniel Jaimes</span></li>
        <li>Autopresentación de los asistentes</li>
        <li>Seguimiento y avance de acuerdos - <span className="color_azul_claro_2">Lizbeth Contreras</span>
          <ol type="a">
            <li>Reporte general de las reuniones de trabajo del Comité en el periodo mayo-agosto</li>
            <li>Presentación de la nueva versión del documento “Criterios mínimos en seguridad con los que deben contar los parques industriales” (antes Parque Industrial Seguro)</li>
            <li>Presentación del directorio con nuevos consultores expertos en materia de seguridad</li>
            <li>Estatus de la actualización de la Norma Mexicana de Parques Industriales
              <ol type="i">
                <li>Situación actual del comité (CTNNPI)</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Logística
          <ol type="a">
            <li>Sugerencias de lineas de acción por parte de los asistentes</li>
            <li>Actividades sugeridas</li>
          </ol>
        </li>
        <li>Propuestas de eventos y capacitación</li>
      </ol>
      </>]}
  ]

  let visualizar_agenda = conferencia
    .filter((item) => {
      if (item.dia === dia) {
        return true;
      } else {
        return false;
      }
    })
    .map((da, index) => {
      return (
        <Row
          
          align="middle"
          style={{ padding: "15px 0px" }}
          className={da.back}
        >
          <Col xs={{span:24, offset:0}} md={{span:4, offset:0}}>
            <p className="hora_agenda">{da.hora}</p>
          </Col>
          <Col xs={{span:24, offset:0}} md={{span:17, offset:3}}>
            {da.actividad.map((datos) => {
              return (
                <>
                  <Row justify="center" align="middle">
                    <Col xs={24} md={24} style={{ paddingLeft: "10px" }}>
                      {datos.personas != "" ? (
                        <>
                          {datos.personas.map((item) => {
                            return (
                              <>
                                <Row align="middle">
                                  <Col xs={24} md={24}>
                                    <p className="nombre_agenda">
                                      {item.nombre}
                                    </p>
                                    <p className="cargo_nombre">{item.cargo}</p>
                                    {datos.desc != "" && (
                        <p className="desc_agenda">{datos.desc}</p>
                      )}
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}
          </Col>
        </Row>
      );
    });

    let ver_texto_modal = ordenes
    .filter((item) => {
      if (ver_order === item.orden) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          {seccion.texto}
        </Col>
      );
    });

  return (
    <Row className="back_titulo_agenda">
      <Col span={20} offset={2}>
        <Row justify="center" align="middle" style={{ position: "" }}>
          <Col
            span={24}
            offset={0}
            style={{ position: "", width: "100%", paddingTop:'3%' }}
          >
            <img src={titulo} style={{width:'100%'}} />
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
        <p className="txt_cabos_agenda color_azul_claro_2">
          <strong>HORARIO LOCAL </strong> (LOS CABOS, BCS) / <strong>CÓDIGO DE VESTIMENTA:</strong> CASUAL DE NEGOCIOS
          </p><br />
        </Col>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        <Row justify="space-around">
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(1)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 1 ? "back_azul_claro_2 puntero" : "back_tranparencia puntero"
            }>
            <p className="color_blanco tex_agenda_dia">
              <strong>Día</strong>{" "}
              <strong className={dia === 1 ? "color_azul" : "color_azul_claro_2"}>
                01
              </strong>
              <br />
              <span className="fecha_text_agenda">15 noviembre, 2023</span>
            </p>
            </div>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(2)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 2 ? "back_azul_claro_2 puntero" : "back_tranparencia puntero"
            }>
            <p className="color_blanco tex_agenda_dia">
              <strong>Día</strong>{" "}
              <strong className={dia === 2 ? "color_azul" : "color_azul_claro_2"}>
                02
              </strong>
              <br />
              <span className="fecha_text_agenda">16 noviembre, 2023</span>
            </p>
            </div>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(3)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 3 ? "back_azul_claro_2 puntero" : "back_tranparencia puntero"
            }>
            <p className="color_blanco tex_agenda_dia">
              <strong>Día</strong>{" "}
              <strong className={dia === 3 ? "color_azul" : "color_azul_claro_2"}>
                03
              </strong>
              <br />
              <span className="fecha_text_agenda">17 noviembre, 2023</span>
            </p>
            </div>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            onClick={() => setDia(4)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 4 ? "back_azul_claro_2 puntero" : "back_tranparencia puntero"
            }>
            <p className="color_blanco tex_agenda_dia">
              <strong>Día</strong>{" "}
              <strong className={dia === 4 ? "color_azul" : "color_azul_claro_2"}>
                04
              </strong>
              <br />
              <span className="fecha_text_agenda">18 noviembre, 2023</span>
            </p>
            </div>
          </Col>
        </Row><br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        
        {visualizar_agenda}

        <br />
        <br />
        {/*<a href={pdf_espanol} className='btn_aqua_agenda' download>DESCARGAR AGENDA</a>
          <span style={{width:'40px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <p style={{textAlign:'right'}}><a href={pdf_ingles} className='btn_aqua_agenda' download>AGENDA INGLES</a></p>*/}
        
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
        <p className="txt_cabos_agenda color_azul_claro_2">
          <strong>HORARIO LOCAL </strong> (LOS CABOS, BCS) / <strong>CÓDIGO DE VESTIMENTA:</strong> CASUAL DE NEGOCIOS
          </p><br /><br />
        </Col>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </Row>
  );
};
export default Agenda;
