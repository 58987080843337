import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import "../../assets/css/ponentes.css";
import { Link } from "react-router-dom";

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_conferencistas.png';
import sergio_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Sergio_Argaelles_espanol.jpg";
import peter_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Peter_Linneman_Ingles.pdf";
import matt_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Matt_Brady_ingles.pdf";
import hamid_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Hamid_Moghadam_espanol.pdf";
import { CaretLeftFilled, CaretRightFilled, CloseOutlined } from "@ant-design/icons";

import ponente1 from "../../assets/images/reunion_anual/ponentes/Karla Berman_AMPIP_ponentes.jpg";
import ponente2 from "../../assets/images/reunion_anual/ponentes/Rodrigo Pacheco_AMPIP_ponentes.jpg";
import ponente3 from "../../assets/images/reunion_anual/ponentes/SergioArguelles_AMPIP_ponentes.jpg";
import ponente4 from "../../assets/images/reunion_anual/ponentes/Lyman Daniels_AMPIP_ponentes.jpg";
import ponente5 from "../../assets/images/reunion_anual/ponentes/Chucho Ramirez_AMPIP_ponentes.jpg";
import ponente6 from "../../assets/images/reunion_anual/ponentes/XochitlGalvez_AMPIP_ponentes.jpg";
import ponente7 from "../../assets/images/reunion_anual/ponentes/Badir Trevino-Mohamed_AMPIP_ponentes.jpg";
import ponente8 from "../../assets/images/reunion_anual/ponentes/Santiago Sepulveda_AMPIP_ponentes.jpg";
import ponente9 from "../../assets/images/reunion_anual/ponentes/Santiago_Vilagomez_PONENTES.jpg";
import ponente10 from "../../assets/images/reunion_anual/ponentes/Ena-Gutierrez-_ponentes.jpg";
import ponente11 from "../../assets/images/reunion_anual/ponentes/Alfredojalife_AMPIP_ponentes.jpg";
import ponente12 from "../../assets/images/reunion_anual/ponentes/CARLAortiz_ponentes.jpg";
import Slider from "react-slick";

const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {
    nombre: "Sergio Argüelles González",
    empresa: "",
    cargo: "Presidente AMPIP",
    img: ponente3,
    url: sergio_con,
    semblanza: [<>Es Presidente y Director General de FINSA, guiando la transformación de la compañía de una empresa familiar a uno de los más importantes desarrolladores inmobiliarios. En 2014 fue nombrado EY Emprendedor del Año en México.
<br /><br />
      Es Presidente del Consejo Regional Nuevo León de BBVA, participa como miembro del consejo de COPARMEX, AMERICAN CHAMBER, Nacional Financiera) y NEXXUS CAPITAL. Participa también como miembro del consejo de la Fundación FINSA y en la Universidad de Monterrey – UDEM.
      <br /><br />
      Actualmente, es el Presidente del Consejo Directivo de la Asociación Mexicana de Parques Industriales Privados (AMPIP), que agrupa a todos los actores relacionados con el sector inmobiliario industrial en México.
      <br /><br />
      Es Economista graduado de Texas A&M University, con posgrado en Administración y Finanzas del IPADE, además de contar con cursos especializados en la Escuela de Administración Kellogg de la Universidad Northwestern.
      
    </>],
  },
  {
    nombre: "Karla Berman",
    empresa: "",
    cargo: "",
    img: ponente1,
    url: sergio_con,
    semblanza: [<>
    Ha estado 15 años en procesos de transformación digital y emprendimiento.<br /><br />
Es inversionista, board member y experta en ventas y marketing, así como apasionada de temas de diversidad en el mundo corporativo. Actualmente es Shark en SHARK TANK México. <br /><br />
Ha trabajado como directora en Google, SoftBank, CNN, además de ser parte de algunas de las startups más exitosas de la región. <br /><br />
Es Ingeniera Industrial por la Ibero y MBA Harvard Business School. Es consejera de Endeavor MX y de HBS. 
</>],
  },
  {
    nombre: "Rodrigo Pacheco",
    empresa: "",
    cargo: "",
    img: ponente2,
    url: sergio_con,
    semblanza: [<>Rodrigo Pacheco es un periodista de negocios, ha impartido conferencias a cámaras empresariales, líderes de negocios, empresas y universidades, con el enfoque de transmitir un análisis de México y su desenvolvimiento económico influido por factores políticos y sociales. Además aborda las grandes tendencias globales por las que navega el país en el siglo XXI. Rodrigo actualmente es el titular de Imagen Empresarial, programa de radio en donde se abordan temas del mundo corporativo, economía y finanzas, con un enfoque fresco y dinámico con un fuerte énfasis en la información internacional, de tecnología e innovación y su vinculación con la coyuntura mexicana.<br /><br />
      Además, Rodrigo colabora en distintos espacios informativos de Grupo Imagen Multimedia y publica una columna semanal en el periódico Excélsior.<br /><br />
      Es miembro del consejo editorial de la Revista ISTMO del IPADE. Rodrigo es licenciado en Relaciones Internacionales egresado del Tecnológico de Monterrey.
    </>],
  },
  {
    nombre: "Lyman Daniels",
    empresa: "",
    cargo: "",
    img: ponente4,
    url: sergio_con,
    semblanza: [<>
    Es el presidente de CBRE México desde 2009 y es el ejecutivo responsable de las operaciones en México y Colombia.
Sus responsabilidades en la empresa incluyen dar dirección estratégica como jefe de la organización de servicios inmobiliarios líder en el mercado de México y Colombia con más de 1,000 empleados que realizan servicios de asesoría y transacciones, valuaciones, loan services y administración de propiedades. CBRE México, bajo su liderazgo, ha sido reconocida como la oficina de más rápido crecimiento orgánico en todo CBRE a nivel global durante más de cinco años.<br /><br />
Al llegar a México en 1994 para Hines, el desarrollador y gerente de bienes raíces privados, fundó y creció rápidamente un negocio de desarrollo y administración de propiedades / instalaciones en México para incluir asignaciones en edificios de oficinas de clase A multi-inquilino, edificios de instalaciones corporativas, complejos de apartamentos de lujo y parques industriales a gran escala individuales y multi-inquilinos. También supervisó las carteras combinadas de más de 100 propiedades, supervisó el desarrollo de $ 800 millones en inversiones inmobiliarias comerciales y fue responsable de la primera venta de activos múltiples para un fondo de pensiones de los Estados Unidos en México. Ha jugado un papel directo en la institucionalización del mercado de bienes raíces comerciales en toda América Latina trabajando extensamente en Panamá y Colombia.<br /><br />
Lyman es autor de numerosas publicaciones en la industria de bienes raíces comerciales. Es miembro fundador y fue presidente del Urban Land Institute (ULI) en México. Anteriormente fue Presidente de la Región Internacional de la Asociación de Propietarios y Gerentes (BOMA) durante cinco años consecutivos. Actualmente, forma parte de la junta ejecutiva de la fundación sin fines de lucro del Hospital ABC (American British Cowdray) con sede en la Ciudad de México.
    </>],
  },
  {
    nombre: "Jesús “Chucho” Ramírez",
    empresa: "",
    cargo: "",
    img: ponente5,
    url: sergio_con,
    semblanza: [<>
    Jesús, “Chucho” Ramírez como se le conoce popularmente, goza de una larga y exitosa carrera como Futbolista profesional, director técnico y formador de destacados futbolistas profesionales, analista y comentarista deportivo de eventos internacionales y como conferencista para más de 200 empresas tanto en México como en el extranjero.<br /><br />
Obtuvo para México el primer campeonato Mundial en su historia, enviando un valioso mensaje a las nuevas generaciones a través del futbol y motivando al país al hacer realidad un sueño que se pensaba imposible de alcanzar.<br /><br />
Sus conferencias conmueven a la audiencia y le han dado, junto con el Campeonato Mundial, el prestigio del que ahora goza como uno de los grandes motivadores de nuestro país<br /><br />
Ahora, comparte su experiencia nacional e internacional basada en los logros obtenidos en los campos deportivos y empresariales, a través de un abanico de servicios para empresas e instituciones deportivas.

    </>],
  },
  {
    nombre: "Xóchitl Gálvez",
    empresa: "",
    cargo: "",
    img: ponente6,
    url: sergio_con,
    semblanza: [<>
    Bertha Xóchitl Gálvez Ruiz es una política, ingeniera y empresaria mexicana. Actualmente es senadora de la República desde el 1 de septiembre de 2018 y pertenece al grupo parlamentario del Partido Acción Nacional. También se ha desempeñado como comisionada nacional para el Desarrollo de los Pueblos Indígenas entre 2003 y 2006 durante la presidencia de Vicente Fox y como jefa delegacional de Miguel Hidalgo entre 2015 y 2018. El 31 de agosto de 2023, fue designada por el Frente Amplio por México como su candidata para las elecciones presidenciales de México de 2024. 

    </>],
  },
  {
    nombre: "Badir Treviño-Mohamed",
    empresa: "",
    cargo: "",
    img: ponente7,
    url: sergio_con,
    semblanza: [<>
    El Lic. Treviño-Mohamed asesora a desarrolladores, administradores e inversionistas inmobiliarios mexicanos e internacionales (incluyendo fondos privados de inversión inmobiliaria, CKDs, CERPIS y FIBRAS) en todos los aspectos legales y de regulación mexicana de inversiones directas y estructuradas en, o adquisiciones estratégicas, de inmuebles en los sectores industriales, de distribución, usos mixtos, comerciales, turísticos, residenciales, data centers y urbanos.<br /><br />
Particularmente, el Lic. Treviño-Mohamed asesoró a FIBRA Macquarie en la adquisición, por aproximadamente US$1.5 mil millones, del portafolio inicial de FIBRA Macquarie compuesto de dos portafolios de inmuebles conformados por 244 propiedades industriales localizadas en 21 ciudades y 15 estados dentro de México, la cual representó la adquisición de inmuebles más grande en México hasta esa fecha. Desde entonces, ha participado en diversas adquisiciones y ventas de FIBRA Macquarie por más de US$1 mil millones.<br /><br />
El Lic. Treviño-Mohamed ha sido reconocido desde 2015 como abogado líder en el área de Derecho Inmobiliario por múltiples directorios legales como Chambers Latin America: Latin America´s Leading Lawyers, y The Legal 500 y Latin Lawer.<br /><br />
El Lic. Treviño-Mohamed es miembro del Colegio de Abogados de México, ha escrito múltiples artículos y documentos sobre bienes raíces y es miembro del comité de administración del ULI (Urban Land Institute; México), incluyendo del sub-comité de ULI Monterrey.<br /><br />
El Lic. Treviño-Mohamed nació en la ciudad de Monterrey, México. Obtuvo su título de Licenciado en Derecho por la Universidad de Monterrey en Monterrey, México en 2001, y su Maestría en Derecho de Northwestern University, Pritzker School of Law en 2006. La lengua materna del Lic. Treviño-Mohamed es el español y domina el idioma inglés.
    </>],
  },
  {
    nombre: "Santiago Sepúlveda",
    empresa: "",
    cargo: "",
    img: ponente8,
    url: sergio_con,
    semblanza: [<>
    El Lic. Sepúlveda tiene amplia experiencia representando a clientes mexicanos y transnacionales en la estructuración, desarrollo y financiamiento de importantes proyectos de infraestructura de petróleo y gas, telecomunicaciones y transporte en México, así como en operaciones especializadas de M&A e inversión de capital privado.<br /><br />
Recientemente, el Lic. Sepúlveda represento a KKR, el fondo norteamericano de capital privado, en la estructuración y negociación con Pemex de una operación de sale-leaseback, primera en su tipo, por US$1.2 mil millones sobre bienes de exploración y producción. Asimismo, el Lic. Sepúlveda representó al consorcio formado por Sierra Oil & Gas, Talos Energy y Premier Oil en su exitosa participación en el primer proceso de licitación para los contratos de exploración-producción de petróleo dentro de la Reforma Energética que abrió el sector de petróleo y gas a inversión privada por primera vez en casi un siglo.<br /><br />
El Lic. Sepúlveda representa a importantes empresas de energía desarrolladores de proyectos, empresas de ingeniería y construcción, así como a prestadores e inversionistas en proyectos de infraestructura en México. Además, de su amplio conocimiento en el are de financiamiento de proyectos, el Lic. Sepúlveda esta regularmente involucrado asesorando a patrocinadores, desarrolladores, inversionistas y financieros en todos los aspectos de permisos, regulación, cumplimiento, comercial y contractuales de sus proyectos. También representa en relación con la adquisición y desinversión de acciones y activos en proyectos relacionados con energía y transportación, y los asesora en los temas de regulación y competencia de sus operaciones. El Lic. Sepúlveda regularmente representa a fondos de capital privado, invirtiendo en proyectos de energía y recursos naturales, en temas de adquisiciones, desinversiones, temas regulatorios y en todos los aspectos legales de su operación cotidiana.<br /><br />
El Lic. Sepúlveda ha sido reconocido como Abogado Destacado en las áreas de Energía, Recursos Naturales, Proyectos y Financiamiento de Proyectos por Chambers and Partners Latin America, The International Who´s Who de Abogados de Energia, IFLR 1000, Latin Lawyer 250 y Best Lawyers.<br /><br />
El Lic. Sepúlveda es miembro de la Barra Mexicana Colegio de Abogados y del International Bar Association. El Lic. Sepúlveda trabajo como asociado internacional en la oficina de Mayer Brown en la ciudad de Nueva York.<br /><br />
El Lic. Sepúlveda nació en la Ciudad de México, México, en donde obtuvo su título de Licenciado en Derecho del Instituto Autónomo de la Ciudad de México en 1996. Obtuvo su Maestría en Derecho de Columbia University School of Law en 1998. La lengua materna del Lic. Sepúlveda es español y domina el idioma inglés.

    </>],
  },
  {
    nombre: "Santiago Villagómez",
    empresa: "",
    cargo: "",
    img: ponente9,
    url: sergio_con,
    semblanza: [<>
    Santiago es CEO y cofundador de Energía Real, una empresa de energía pionera en la venta de energía renovable generada en sitio. Desde su inicio, post-desregulación del sector energético mexicano, Energía Real ha sido líder en PPAs de energía distribuida comercial e industrial. Sus clientes incluyen FIBRAS y fondos inmobiliarios de capital privado internacional, así como algunas de las empresas privadas más grandes de México.
    <br /><br />
    En 2021, Riverstone adquirió una participación en Energía Real y comprometió capital para crecimiento. El compromiso de capital total permite a Energía Real continuar con su objetivo de desarrollar 500MW de instalaciones solares, cubriendo hasta 4 millones de metros cuadrados de azoteas y compensando más de 400.000 toneladas de CO2e por año.
    <br /><br />
Antes de Energía Real, Santiago fue Jefe de Inversiones en Gran Ciudad (encargado de invertir $111 millones en desarrollos inmobiliarios); así como Asociado de Inversiones y Gestión de Activos en MIRA, donde invirtió US$140 millones en 9 transacciones inmobiliarias.
<br /><br />
Desde 2018, Santiago es asesor de Dark Horse Ventures, un fondo de capital de riesgo regional.
<br /><br />
Santiago es Ingeniero Químico, de la Universidad Iberoamericana en la Ciudad de México.
    </>],
  },
  {
    nombre: "Ena Gutiérrez",
    empresa: "",
    cargo: "",
    img: ponente10,
    url: sergio_con,
    semblanza: [<>
    Ena ha sido parte del sector de generación distribuida en México desde hace 8 años. Gracias a su liderazgo en el proyecto, Enlight fue la primera empresa en desarrollar su propio sistema de monitoreo, Apollo.
    <br /><br />
    Ena cuenta con un amplio conocimiento en el desarrollo de proyectos fotovoltaicos y de almacenamiento de energía y debido a ello, se ha dedicado a crear la nueva unidad de negocio de almacenamiento de energía en Enlight. Su rol como Directora de Nuevos Negocios abarca la ejecución del proceso comercial para proyectos fotovoltaicos y de almacenamiento de energía, así como la elaboración del modelo financiero para ofrecer estos proyectos como servicios financiados en el esquema PPA Onsite y SaaS (Storage as a Service).
    <br /><br />
    Ena es Ingeniera en Desarrollo Sustentable por el Tecnológico de Monterrey y se ha especializado en el desarrollo de negocios enfocado en microredes y soluciones distribuidas en México y Latinoamérica.
    </>],
  },
  {
    nombre: "Alfredo Jalife",
    empresa: "",
    cargo: "",
    img: ponente11,
    url: sergio_con,
    semblanza: [<>
    El Dr. Alfredo JALIFE-RAHME especialista en neuroendocrinología y e egresado de los Institutos Nacionales (de Nutrición; y de Neurología & Neurocirugía).
    <br /><br />
Impartió clases en Psicología en la Universidad de las Americas A.C desde 1978 hasta 1990 por lo que fue homenajeado por su labor docente. Obtuvo en la misma Universidad de las Americas A. C, la Maestría en Administración de Empresas "MBA"(Master Business Administration) en 1987. 
<br /><br />
Ha sido profesor universitario en varias casas de estudios nacionales e internacionales.
<br /><br />
En 1997 fue invitado a impartir un curso en la Universidad de Tréveris, Alemania, galardonado con la "Cátedra UNESCO", bajo los auspicios de la Comisión de Estudios Europeos y la Unión Europea:"Europa en la Perspectiva Ibero-Americana".Invitado por la misma Universidad de Tréveris y bajo los mismos auspicios a coordinar,con varios profesores universitarios europeos, el taller "La Globalización y los Efectos en Europa". 
<br /><br />
Ese mismo año fue invitado por la ONU a participar en un “Seminario sobre el Medio-Oriente” en Atenas, Grecia.Seleccionado por la ONU a formar parte de una “Misión de Noticias y Hallazgo de Hechos” en Egipto y Jordania.
<br /><br />
Co-fundador de la filial mexicana y miembro del Cuerpo de Gobierno de la "Federación Internacional de Médicos para la Prevención de la Guerra Nuclear (Premio NOBEL de la PAZ 1985)”.
<br /><br />
Miembro de la Academia de Ciencias de Nueva York,entre otras sociedades nacionales e internacionales de prestigio mundial.
<br /><br />
Aparece en el "MARQUIS WHO IS WHO IN THE WORLD" y ha sido seleccionado para aparecer en "Hombres de Logros" de Enciclopedia Biográfica de Cambridge, Inglaterra.
<br /><br />
Seleccionado por la revista Líderes Mexicanos entre los 100 mayores expertos del petróleo en México.
<br /><br />
Comentarista y analista de varios medios de comunicación nacionales e internacionales. Analista de GEOECONOMÍA del periódico El Financiero y de Globalización y Geopolítica del periódico LA JORNADA. Colaborador del portal Sputnik en español. Analista de asuntos internacionales en varias radiodifusoras. Comentarista de CNN en español y ADN 40 (TV Azteca).
<br /><br />
Profesor de Post-Grado de la FCA (Facultad de Contaduría y Administración) de la UNAM,en Geopolítica y Negocios Internacionales. 
<br /><br />
Ex-Profesor de Licenciatura en la Facultad de Ciencias Políticas y Sociales de la UNAM.
<br /><br />
Ex-Profesor de Geografía Económica, Relaciones Internacionales y Finanzas Internacionales  en la Universidad de las Américas A.C (Campus México City).
<br /><br />
Ex-asesor del Centro de Estudios de Asia & África del Colegio de México.
<br /><br />
Nombrado Miembro del Comité de Árbitros del Instituto de Investigaciones Económicas de la UNAM,a partir de enero del año 2001.
<br /><br />
Nombrado el “Mejor Analista de Asuntos Internacionales de México” por la revista Líderes Mexicanos (feb.03).
<br /><br />
Profesor del Curso de Seguridad Nacional sobre el Medio-Oriente de la Armada de México.
<br /><br />
Invitado a impartir una conferencia sobre “Gobernanza Global y la Guerra de Iraq” en el Instituto de Desarrollo Alemán(Bonn; Agosto 09).
<br /><br />
Invitado Especial al Aniversario 50 y al Aniversario 10 de la licenciatura de Relaciones Internacionales de la Universidad jesuita de Guadalajara, ITESO.
<br /><br />
Desde 2012 se inauguró la “Cátedra Alfredo Jalife-Rahme” en el Centro de Estudios Geoestratégicos de la UAM-X.
<br /><br />
En 2016 Réseau Voltaire lo califica como “El máximo experto en geopolítica de Latinoamérica”. 
<br /><br />
Catalogado en el Ranking Global de los Mejores Analistas de Geopolítica: Primer lugar de Latinoamérica y Tercer lugar mundial. 
<br /><br />
Máximas calificaciones del Posgrado de la UNAM en 2022 en las asignaturas de Geopolítica y Entorno de las Organizaciones.
    </>],
  },
  {
    nombre: "Carla Ortiz",
    empresa: "",
    cargo: "",
    img: ponente12,
    url: sergio_con,
    semblanza: [<>
    Carla es Directora General y liderea el desarrollo de RER en México y LATAM, es Consejera de Asolmex, cuenta con una década de experiencia en el sector solar, una Maestría en Energía Renovable por la Universidad de Duke y una trayectoria en el sector financiero que le han ayudado impulsar exitosamente el crecimiento de RER y la colocación de fondos de inversión para energía solar y almacenamiento.
    </>],
  },
];
const Ponentes = (props) => {
  const ref_carousel = React.createRef();
  const [hover, setHover] = useState(false);
  const [selec_person, setSelectPerson] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      
      <CaretRightFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px' }} onClick={onClick} />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
    );
  }

  const settingsPonentes = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows:true,
    nextArrow:<SampleNextArrow /> ,
    prevArrow:<SamplePrevArrow  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      }
    ]
  };

  let ver_ponentes = expositor.map((seccion, index) => {
    return (
      <>
        <div className="contenedor_imagen_con">
          <img src={seccion.img} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con color_naranja bold_font">{seccion.nombre}</p>
              <br />
              <p className="empresa_con color_blanco">{seccion.empresa}</p>
              <p className="cargo_con color_blanco">{seccion.cargo}</p>
              <br />
              <p style={{display:'non'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con color_aqua"
              >
                Semblanza
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>
        <br />
        </>
    );
  });
  let ver_texto_modal = expositor
    .filter((item, index) => {
      if (selec_person === index) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          <p className="nombre_modal">{seccion.nombre}</p>
          <p className="empresa_modal">{seccion.empresa}</p>
          <p className="cargo_modal">{seccion.cargo}</p>
          <br />
          <p className="semblanza_modal">{seccion.semblanza}</p>
          <br />
        </Col>
      );
    });

  return (
    <>
      <Row justify="center" align="middle" className="contenedor_ponentes back_hueso">
        <Col span={24} offset={0}>
          <Row justify="center" align="middle">
            <Col
              span={24}
              offset={0}>
             <img src={titulo}  style={{width:'100%'}} />
            </Col>
          </Row>
        </Col>
        <Col xs={10} md={20}>
       <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'0px'}} className="slide_ponente">
        {ver_ponentes}
        </Slider>
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
};
export default Ponentes;
