import React, { useState, useContext, useEffect, useRef } from "react";
import RegistroCompleto from "./RegistroCompleto";
import "../../assets/css/login.css";
import { Auth } from "../../utils/AuthContext";

import logo_banner_ergistro from "../../assets/images/casa/titulo_casaduana_26enero.png";

import logo_banner_ergistro_movil from "../../assets/images/casa/titulo_casaduana_26enero_movil.png";
import titulo_img from "../../assets/images/segunda_reunion/REGISTRO/registro_ampip.png";
import titulo_img_en from "../../assets/images/segunda_reunion/REGISTRO/register_-eng.png";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber,
} from "antd";
import Firebase from "../../utils/firebaseConfig";
import {
  mail_registro_confirmacion,
  existe_empresa,
} from "../../utils/ConexionServidor";

import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

import img_crear from "../../assets/images/evento/1_REGISTRO/btn_registro.png";
import img_ayuda from "../../assets/images/tercera_reunion/REGISTRO/btn_problemasregsitro_.png";
import img_ayuda_en from "../../assets/images/tercera_reunion/REGISTRO/btn_problemasregsitro_.png";
import btn_ayuda from "../../assets/images/tercera_reunion/btn_whastapp.png";
import btn_ayuda_en from "../../assets/images/tercera_reunion/btn_whastapp.png";

import wts from "../../assets/images/registro/btn_problemasregistro.png";
import boton_ya_cuenta from "../../assets/images/temp/image200x100.png";

import LoginExiste from "../registro/LoginExiste";
import { FacebookFilled, FacebookOutlined, InstagramOutlined, WhatsAppOutlined, YoutubeOutlined } from "@ant-design/icons";

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 10,
  });
};

const nom_evento = "primera-reunion-2024";

const { Option } = Select;

let correo_registrado = "",
  password_registrado = "",
  perfil_registrado = "";

const app = new Firebase();

const success = () => {
  openNotificationWithIcon("success", "Bienvenido", "");
};

const Registro = ({ setsignup, history, tipo_registro, idioma }) => {
  const [otro_medio, setOtroMedio] = useState(false);
  const [confirmacion, setConfirmacion] = useState(false);
  const [cargando_regsitro, setCargandoRegistro] = useState(false);
  const { usuario, datos } = useContext(Auth);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [reutilizar_datos, setReutilizarDatos] = useState(false);
  const [tipo_perfl, setTipoPerfil] = useState("");
  const [tipo_membresia, setTipoMembresia] = useState("");
  const [nombre_completo, setNombreCompleto] = useState("");
  const [empresa_repetida, setEmpresaRepetida] = useState(false);
  const [perfil_elegido, setPerfilElegido] = useState(false);

  const [form] = Form.useForm();

  const ref_form = React.createRef();
  const ref_organizacion = React.createRef();
  const ref_recaptcha = React.createRef();

  const elegirMedio = (value) => {
    console.log(value);

    if (value === "Otro") {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }
  };

  useEffect(() => {
    if (usuario) {
      app.app
        .auth()
        .signOut()
        .then(function () {})
        .catch(function (error) {
          // An error happened.
        });
    }
  }, []);

  useEffect(() => {
    setEmpresaRepetida(false);
  }, [tipo_perfl]);

  useEffect(() => {
    console.log("se reutiliza: ", reutilizar_datos);
    if (reutilizar_datos) {
      if (datos) {
        console.log("existen los datos");

        datos.nombre_curso = nom_evento;
        datos.apellidos = datos.ap_paterno + " " + datos.ap_materno;

        mail_registro_confirmacion(datos).then(({ data, status }) => {
          switch (status) {
            case 0:
              //delete values.aceptar_terminos;
              app.escribir(
                nom_evento + "/usuarios",
                undefined,
                datos,
                () => {}
              );

              openNotificationWithIcon("success", "", data);
              setConfirmacion(true);
              break;
            default:
              openNotificationWithIcon("warning", "", data);
              setConfirmacion(false);
              break;
          }
          //setCargandoRegistro(false);
          setMostrarLogin(false);
        });
        setReutilizarDatos(false);
      } else {
        console.log("no existen los dtos");
      }
    }
  }, [datos, reutilizar_datos]);

  const registrarLoginExiste = (medio) => {
    setReutilizarDatos(true);
  };

  const onFinish = async (values) => {
    if (empresa_repetida) {
      openNotificationWithIcon(
        "warning",
        "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
        "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”"
      );
      return;
    }

    setCargandoRegistro(true);

    let recaptcha = "sin-recaptcha"; //ref_recaptcha.current.getValue();

    values.correo = values.correo.toLowerCase();
    values.idioma = idioma;

    const { correo } = values;

    setNombreCompleto(values.nombre + " " + values.ap_paterno);

    let contra = values.password;

    correo_registrado = correo;

    values.nombre_curso = nom_evento;

    values.apellidos = values.ap_paterno;

    values["g-recaptcha-response"] = recaptcha;

    const acentos = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
    };
    values.pass = values.ap_paterno
      .split("")
      .map((letra) => acentos[letra] || letra)
      .join("")
      .toString()
      .toLowerCase();

    password_registrado = values.pass;
    perfil_registrado = values.perfil;

    values.tipo_entrada = "presencial";

    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          console.log(values);
          delete values.medios;
          delete values.pass;
          delete values.aceptar_terminos;
          //delete values.aceptar_networking;
          delete values.comite_infraestructura;
          delete values.comite_asg;
          delete values.comite_promocion;

          delete values.otro_procedencia;

          openNotificationWithIcon("success", "", data);
          setConfirmacion(true);
          break;
        default:
          openNotificationWithIcon("warning", "", data);
          setConfirmacion(false);
          break;
      }
      setCargandoRegistro(false);
    });
  };

  const revisarEmpresa = (empresa) => {
    if (tipo_registro === "presencial") {
      existe_empresa({ empresa: empresa }).then(({ data }) => {
        if (data === 1) {
          openNotificationWithIcon(
            "warning",
            "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
            "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”"
          );
          setEmpresaRepetida(true);
        } else {
          setEmpresaRepetida(false);
        }
      });
    }
  };

  const revisarPErfil = (perfil) => {
    if (perfil !== "") {
      setPerfilElegido(true);
    }
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
        <Row className="back_registro">
          <Col span={24}>
            <Row
              justify="center"
              align="middle"
            >
              <Col
                xs={0}
                md={14}
              >
                <img src={logo_banner_ergistro} style={{ width: "100%" }} />
              </Col>
              <Col
                xs={24}
                md={0}
              >
                <img src={logo_banner_ergistro_movil} style={{ width: "100%" }} />
              </Col>
            </Row>
          </Col>

          {confirmacion ? (
            <Col span={24}>
              <RegistroCompleto
                correo={correo_registrado}
                password={password_registrado}
                tipo_registro={tipo_registro}
                nombre={nombre_completo}
                idioma={idioma}
              />
            </Col>
          ) : (
            <>
              <Col xs={{ span: 18, offset: 3 }} md={{ span: 18, offset: 3 }}>
                <Form
                  className="login-form"
                  ref={ref_form}
                  form={form}
                  layout="vertical"
                  name="basic"
                  initialValues={{
                    remember: true,
                    organizacion: "",
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col xs={{span:20, offset:2}} md={{span:20, offset:2}} >
                      <Row
                        justify="center"
                        align="middle"
                      >
                        <Col
                          xs={24}
                          md={20}
                        >
                          <p className="color_blanco desc_registro">
                          Conoce las modificaciones a las RGCE y a la RMF que, para 2024 impactarán la operación aduanera. Así mismo, todas las actualizaciones entorno al complemento Carta Porte y sus repercusiones en las operaciones de comercio exterior y que viene para este 2024 en el desarrollo de las cadenas de suministro por el efecto "nearshoring".
                          </p>
                          <p
                            className="color_blanco textos_titulos_secciones"
                            style={{ textAlign: "center" }}
                          >
                            REGÍSTRATE
                          </p>
                          <br />
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 0 }}
                    >
                      <Form.Item
                        className="nom-label"
                        name="nombre"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Ingresa tu nombre"
                                  : "Enter your name"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          autocomplete="nope"
                          placeholder=" Nombre"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}
                    >
                      <Form.Item
                        className="nom-label"
                        name="ap_paterno"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Ingresa tu apellido paterno"
                                  : "Enter your father's last name"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          autocomplete="nope"
                          placeholder=" Apellido Paterno"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        className="nom-label"
                        name="correo"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Ingresa un correo válido"
                                  : "Enter a valid email"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          autocomplete="nope"
                          placeholder=" Correo electrónico"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        className="nom-label"
                        name="empresa"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }}>
                                {idioma === "es"
                                  ? "Ingresa tu empresa"
                                  : "Enter your position"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input className="inputs" autoComplete="off" placeholder=" Empresa" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        className="nom-label"
                        name="telefono"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }}>
                                {idioma === "es"
                                  ? "Ingresa tu telefono"
                                  : "Enter your position"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input 
                          className="inputs"
                          autoComplete="nope"
                          autocomplete="nope" placeholder=" Celular" type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: "left" }}>
                      <Form.Item
                        className="nom-label"
                        name="comite_promocion"
                        valuePropName="checked"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Checkbox className="nom-label">
                          <span
                            style={{ fontSize: "16px" }}
                            className="color_blanco"
                          >
                            Forma parte de nuestra lista de difusión en <a href="https://api.whatsapp.com/send?phone=8671262082" className="color_verde" target="_blank"><WhatsAppOutlined /> WhatsApp</a>
                          </span>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    {/*<Col span={24}>
                        <Form.Item className="nom-label"
                          name="medio"
                          label={<span className="color_gris_oscuro">¿Por qué medio te enteraste del evento?</span>}
                          rules={[
                            {
                              required: true,
                              message: <span style={{ color: 'red' }}></span>,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      tipo_registro === 'presencial' &&
         <Col span={24} style={{textAlign:'left'}}>
         <Form.Item className="nom-label"
     name="asistir_miercoles"
     valuePropName="checked"
     rules={[
       {
         required:false
       }
     ]}
   >
     <Checkbox className="nom-label">
     <span style={{fontSize:'16px'}} className="">¿Asistirás a la cena de socios del miércoles 10 de noviembre del 2021?</span>
     </Checkbox>
   </Form.Item>
         </Col>
    */}

                    {/*<Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item className="nom-label"
                          name="aceptar_networking"
                          valuePropName="checked"
                          rules={[
                            {
                              required: false
                            }
                          ]}
                        >
                          <Checkbox className="nom-label">
                            <span style={{ fontSize: '16px' }} className="">Acepto que mis datos sean utilizados para fines estadísticos.</span>
                          </Checkbox>
                        </Form.Item>
                        </Col>*/}
                        <Col span={24}>
                          <Row>
                            <Col xs={{span:18}}>
                            <p 
                            style={{ fontSize: "16px", textAlign:'left' }} className="color_blanco">¡Descubre todas las cápsulas informativas que tenemos para ti! <span style={{fontSize:'20px'}}><a href="https://www.facebook.com/casaduana.mx/" target="_blank" className="color_blanco"><FacebookFilled /></a> <a href="https://www.instagram.com/casaaduanatopo/?hl=es" target="_blank" className="color_blanco"><InstagramOutlined /></a> <a href="https://www.youtube.com/@casaduana" target="_blank" className="color_blanco"><YoutubeOutlined /></a></span></p>
                            </Col>
                            <Col xs={{span:6}}>
                            </Col>
                          </Row>
                        </Col>

                    <Col span={24}>
                      <Form.Item>
                        <br />
                        <p style={{ textArlign: "center" }}>
                          <span
                            className="btn_aqua"
                            onClick={() => {
                              ref_form.current.submit();
                            }}
                          >
                            {idioma === "es" ? "REGISTRO" : "REGISTER"}
                          </span>
                        </p>
                        <br />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div id="recaptcha-container"></div>
                </Form>
                <br /><br />
              </Col>
            </>
          )}
        </Row>
      </Spin>

      <Modal
        title="INICIA SESIÓN"
        visible={mostrar_login}
        onOk={() => {
          setMostrarLogin(false);
        }}
        onCancel={() => {
          setMostrarLogin(false);
        }}
        footer={null}
      >
        <LoginExiste
          onResponse={() => {
            registrarLoginExiste();
          }}
          onClick={success}
        />
      </Modal>
    </>
  );
};

export default withRouter(Registro);
